<template>
  <button
    class="btn btn-secondary btn-sm"
    :title="$t(`uploadVideo.manageImages`)"
    v-on:click="show = !show"
  >
    <BaseIcon name="video" color="var(--black)" />
  </button>
  <Videos v-model:show="show" :id="id" type="product" :is-variant="main !== null && main !== undefined" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import Videos from "@/components/inputs/Videos";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
export default {
  name: "uploadVideo",
  components: {Videos, BaseIcon },
  props: {
    id: Number,
    main: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
      show: false,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
