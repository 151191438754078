<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("videos.videos") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="!uploadWindow">
            <div class="row">
              <div
                class="col-2 my-2 img-uploader-thumbnail"
                v-for="video in videos"
                :key="video.id"
              >
                <img
                  class="img-thumbnail"
                  :src="
                    mediaUrl + '/' + video.path + '/262-' + video.name + '.webp'
                  "
                  :alt="video.name"
                />
                <div class="img-btns">
                  <button class="btn btn-outline-primary">
                    <BaseIcon name="trash" />
                  </button>
                </div>
              </div>
              <div class="col-2 my-2">
                <button class="img-thumbnail" v-on:click="uploadWindow = true">
                  <BaseIcon name="plus-circle" />
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-3 text-start">
                <button
                  class="btn w-100 d-block"
                  v-on:click="uploadMenu = true"
                  :class="{
                    'btn-primary': uploadMenu,
                    'btn-simple': !uploadMenu,
                  }"
                >
                  {{ $t("videos.uploadFile") }}
                </button>
                <button
                  class="btn w-100 d-block"
                  v-on:click="uploadMenu = false"
                  :class="{
                    'btn-primary': !uploadMenu,
                    'btn-simple': uploadMenu,
                  }"
                >
                  {{ $t("videos.browseGallery") }}
                </button>
              </div>
              <div class="col" v-if="uploadMenu">
                <file-pond
                  name="video"
                  ref="pond"
                  label-idle="Drop files here..."
                  v-bind:allow-multiple="true"
                  accepted-file-types="video/mp4, video/avi, video/mov, video/ogg, video/webm"
                  v-bind:files="myFiles"
                  v-on:init="handleFilePondInit"
                  :server="server"
                  :chunkUploads="true"
                  :chunkForce="true"
                  :chunkSize="1000000"
                  :credits="false"
                />
              </div>
              <div class="col" v-else>
                <div class="row">
                  <div
                    class="col-3 my-2 img-uploader-thumbnail"
                    v-for="video in selectable"
                    :key="video.id"
                    v-on:click="addImageToProduct(video)"
                  >
                    <img
                      class="img-thumbnail"
                      :src="
                        mediaUrl +
                        '/' +
                        video.path +
                        '/262-' +
                        video.name +
                        '.webp'
                      "
                      :alt="video.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-end mt-4">
              <button class="btn btn-primary" v-on:click="uploadWindow = false">
                {{ $t("videos.back") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import http from "../../modules/http";
import tenant from "../../modules/tenant";
const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));
const bootstrap = require("bootstrap");

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "ImageEditor",
  props: {
    show: Boolean,
    id: Number,
    isVariant: Boolean,
    type: String,
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  data: function () {
    return {
      uploadWindow: false,
      uploadMenu: true,
      myFiles: [],
      videos: [],
      selectable: [],
      server: {
        process: this.upload,
        revert:
          process.env.VUE_APP_MEDIA_API +
          "/" +
          tenant.getTenantName() +
          "/videos/revert",
        restore:
          process.env.VUE_APP_MEDIA_API +
          "/" +
          tenant.getTenantName() +
          "/videos/restore",
        load:
          process.env.VUE_APP_MEDIA_API +
          "/" +
          tenant.getTenantName() +
          "/videos/load",
        fetch:
          process.env.VUE_APP_MEDIA_API +
          "/" +
          tenant.getTenantName() +
          "/videos/upload/a",
      },
    };
  },
  watch: {
    show() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        myModal.show();
        if (this.type === "product") {
          if (this.isVariant) {
            http
              .fetch("/files/variant-images?variant=" + this.id)
              .then((data) => {
                this.images = data.variant;
                this.selectable = data.product;
              });
          } else {
            http
              .fetch("/files/product-images?product=" + this.id)
              .then((data) => {
                this.images = data;
                this.selectable = [];
              });
          }
        }
      }
    },
  },
  methods: {
    addImageToProduct(video) {
      let addImage = {
        id: this.id,
        video: video.id,
      };

      if (this.isVariant) {
        addImage.is_variant = true;
      }

      http.fetch("/products/add-video", addImage).then(() => {
        this.videos.push(video);
        this.uploadWindow = false;
      });
    },
    upload(
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
      transfer,
      options
    ) {
      console.log(fieldName);
      console.log(file);
      console.log(options);

      const _this = this;
      const request = new XMLHttpRequest();

      let send = { type: this.type, id: _this.id, videos: [file.name] };
      if (_this.isVariant) {
        send.is_variant = true;
      }

      http.fetch("/files/upload", send).then((data) => {
        console.log(data);

        const formData = new FormData();
        formData.append("token", data.token);
        formData.append("album", data.album);
        formData.append("category", this.type);

        request.open(
          "POST",
          process.env.VUE_APP_MEDIA_API +
            "/" +
            tenant.getTenantName() +
            "/videos/upload"
        );

        request.setRequestHeader("Upload-Length", file.size);
        request.setRequestHeader("Upload-Name", file.name);

        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, file.size);
        };

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            transfer(request.responseText);

            _this.chunkUpload(
              0,
              file,
              options,
              fieldName,
              data,
              progress,
              error,
              load,
              request.responseText
            );

            /*
              let data2 = JSON.parse(request.responseText);

              let addImage = {
                id: _this.id,
                image: data2.operation.images[0].id,
              };

              if (_this.isVariant) {
                addImage.is_variant = true;
              }
*/
          } else {
            error("oh no");
          }
        };

        request.send(formData);
      });

      return {
        abort: () => {
          request.abort();
          abort();
        },
      };
    },
    chunkUpload(
      start,
      file,
      options,
      fieldName,
      data,
      progress,
      error,
      load,
      fileId,
      i = 0
    ) {
      let _this = this;
      if (start < file.size) {
        let chunkEnd = Math.min(start + options.chunkSize, file.size);
        let chunkSize = chunkEnd - start;
        const chunk = file.slice(start, chunkEnd);

        const chunkForm = new FormData();
        chunkForm.append(fieldName, chunk, file.name);
        chunkForm.append("token", data.token);
        chunkForm.append("album", data.album);
        chunkForm.append("category", this.type);
        chunkForm.append("_method", "PATCH");

        const request = new XMLHttpRequest();

        request.open(
          "POST",
          process.env.VUE_APP_MEDIA_API + "/videos/upload/" + fileId
        );

        request.setRequestHeader("Upload-Offset", start);
        request.setRequestHeader("Upload-Length", chunkSize);
        request.setRequestHeader("Upload-Name", file.name);

        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, start + e.loaded, file.size);
        };

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            _this.chunkUpload(
              chunkEnd,
              file,
              options,
              fieldName,
              data,
              progress,
              error,
              load,
              fileId
            );
          } else {
            if (i >= options.chunkRetryDelays.length) {
              error("oh no");
            } else {
              setTimeout(() => {
                _this.chunkUpload(
                  start,
                  file,
                  options,
                  fieldName,
                  data,
                  progress,
                  error,
                  load,
                  fileId,
                  i + 1
                );
              }, options.chunkRetryDelays[i]);
            }
          }
        };

        request.send(chunkForm);
      } else {
        load();
      }
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
    },
  },
  components: { BaseIcon, FilePond },
};
</script>
